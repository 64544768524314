<template>
  <div id="app">
    <header v-show="this.$route.meta.isShow !== false">
      <menu-icon @setMenuVisiableTrue="setMenuVisiable(true)"></menu-icon>
    </header>
    <div
      class="mask"
      v-show="menuVisiable"
      @touchmove.prevent
      @click.prevent="setMenuVisiable(false)"
    ></div>

    <transition name="slide-fade">
      <div
        class="menu"
        v-show="menuVisiable"
        @touchstart="touchStart"
        @touchend="touchEnd"
        @touchmove.prevent
      >
        <Icon
          class="close"
          name="close"
          @click.native="setMenuVisiable(false)"
        />
        <router-link to="/home" @click.native="routerClick"
          ><Icon name="home" />首页</router-link
        >
        <router-link to="/product" @click.native="routerClick"
          ><Icon name="production" />产品与服务</router-link
        >
        <router-link to="/news" @click.native="routerClick"
          ><Icon name="news" />公司新闻</router-link
        >
        <router-link to="/about" @click.native="routerClick"
          ><Icon name="aboutus" />关于我们</router-link
        >
        <router-link to="/contact" @click.native="routerClick"
          ><Icon name="contact" />联系我们</router-link
        >
        <router-link v-if="setToken" to="/mycenter" @click.native="routerClick"
          ><Icon name="login" />个人中心</router-link
        >
        <router-link v-else to="/Login" @click.native="routerClick"
          ><Icon name="login" />注册/登录</router-link
        >
      </div>
    </transition>

    <router-view />
    <index-bottom />
    <right-side-btn class="right-btn" />
  </div>
</template>
<script>
import Icon from "./components/Icon.vue";
import IndexBottom from "./components/IndexBottom.vue";
import MenuIcon from "./components/MenuIcon.vue";
import RightSideBtn from "./components/RightSideBtn.vue";
export default {
  components: { Icon, IndexBottom, MenuIcon, RightSideBtn },
  data() {
    return {
      menuVisiable: false,
      touchStartPoint: 0,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    setToken() {
      return this.$store.state.token;
    },
  },
  methods: {
    setMenuVisiable(bool) {
      this.menuVisiable = bool;
    },
    touchStart(event) {
      this.touchStartPoint = event.changedTouches[0].clientX;
    },
    touchEnd(event) {
      let distance = event.changedTouches[0].clientX - this.touchStartPoint;
      if (distance > 30) {
        this.menuVisiable = false;
      }
    },
    routerClick() {
      this.setMenuVisiable(false);
    },
  },
};
</script>
<style lang="less" scoped>
#app {
  min-height: 100vh;
  width: 750px;
  margin: 0 auto;
  header {
    width: 750px;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  .mask {
    width: 750px;
    height: 100vh;
    position: absolute;
    z-index: 101;
    background-color: #535353;
    opacity: 0.4;
  }
  .menu {
    position: fixed;
    top: 0;
    right: 0;
    background: #fff;
    width: 620px;
    height: 100vh;
    z-index: 102;
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    .close {
      font-size: 30px;
      color: #535353;
      position: absolute;
      right: 30px;
      top: 30px;
    }
    a {
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #535353;
      line-height: 110px;
      border-bottom: 2px solid #d9d9d9;
      margin-left: 97px;
    }
    .router-link-active {
      color: #79c113;
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(300px);
  opacity: 0;
}
.right-btn {
  position: fixed;
  top: 70%;
  right: 0;
}
</style>
