<template>
  <div class="wrapper">
    <div class="block" @click="toMessage">
      <img src="../assets/icons/btn2.png" alt="" class="btnimg" />
      <span>留言</span>
      <span>建议</span>
    </div>
    <div class="block" v-if="btnFlag" @click="backTop">
      <img src="../assets/icons/btn1.png" alt="" class="btnimg" />
      <span>顶部</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      btnFlag: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },

    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 0) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
    toMessage() {
      this.$router.push("/message");
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  .btnimg {
    height: 40px;
    margin: 10px 0;
  }
  .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(#99d3ff, #5795e6);
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    width: 80px;
    padding: 10px 0;
    border-radius: 10px;
    margin: 20px 0;
  }
}
</style>
