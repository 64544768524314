import qs from "qs";
import server from "./axios.conf.js";

export const login = function (params) {
  return server({
    method: "post",
    baseURL: "http://110.87.103.59:18086/",
    url: `oauth2/oauth/token`,
    headers: {
      "content-type": "application/x-www-form-urlencoded"
    },
    data: qs.stringify({
      ...params,
      client_id: "client-app",
      client_secret: "123456"
    })
  });
};

export const refresh = function (refreshToken) {
  return server({
    method: "post",
    baseURL: "http://110.87.103.59:18086/",
    url: "oauth2/oauth/token",
    headers: {
      "content-type": "application/x-www-form-urlencoded"
    },
    data: qs.stringify({
      grant_type: "refresh_token",
      client_id: "client-app",
      client_secret: "123456",
      refresh_token: refreshToken
    })
  });
};

// 使用手机号登录发送验证码
export const sendSMSLogin = function (mobile) {
  return server({
    method: "get",
    baseURL: "http://110.87.103.59:18086/",
    url: `oauth2/captcha/validata/smsCode`,
    params: {
      mobile: mobile
    }
  });
};

export const sendSMS = function (mobile) {
  return server({
    method: "get",
    baseURL: "http://110.87.103.59:18086/",
    url: `oauth2/captcha/validata/XNsmsCode`,
    params: {
      businessType: "YHZC",
      mobile: mobile
    }
  });
};

export const regist = function (data) {
  return server({
    method: "post",
    baseURL: "http://110.87.103.59:18086/",
    url: `api-user/user/registerUser`,
    data: data
  });
};

// 验证码修改密码
export const updateSysUserPasswordSms = function (data) {
  return server({
    method: "post",
    url: `http://110.87.103.59:18086/api-user/user/updateSysUserPasswordSms`,
    data: data
  });
};

// 发送验证码并判断手机是否为系统用户
export const ifSmsCode = function (mobile) {
  return server({
    method: "get",
    url: `http://110.87.103.59:18086/oauth2/captcha/validata/XNsmsCode`,
    params: {
      businessType: "ZHMM",
      mobile: mobile
    }
  });
};

// 更改用户信息 
export const registerUser = function (sysUser) {
  return server({
    url: "http://110.87.103.59:18086/api-user/user/registerUser",
    method: "POST",
    data: sysUser
  })
}

// 获取用户的个人信息
export const getLoginUser = function () {
  return server({
    url: "http://110.87.103.59:18086/api-user/user/getLoginUser",
  })
}

// 邮箱发送验证码
export const emailCode = function (data) {
  return server({
    url: "http://110.87.103.59:18086/api-user/user/emailCode",
    method: "POST",
    header: {
      "content-type": "application/json"
    },
    data: data
  })
}

// 验证邮箱
export const emailCodeVerify = function (data) {
  return server({
    url: "http://110.87.103.59:18086/api-user/user/emailCodeVerify",
    method: "POST",
    params: data
  })
}
