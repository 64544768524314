import Vue from "vue";
import App from "./App.vue";
import store from "./store/store";
import router from "./router";
import "./assets/css/reset.css";
import initViewport from "./units/h5";
import "element-ui/lib/theme-chalk/index.css";
import {
  Dialog,
  Toast
} from 'vant';
import 'vant/lib/index.css';
initViewport();
Vue.config.productionTip = false;

Vue.use(Dialog)
Vue.use(Toast);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

const token = localStorage.getItem("token");
const user = JSON.parse(localStorage.getItem("user"));
if (token) {
  // 获取用户信息,初始化VueX
  store.commit("setToken", token)
  store.commit("setUser", user)
}
