import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    lng: localStorage.getItem("lng"),
    lat: localStorage.getItem("lat"),
    address: localStorage.getItem("address"),
    user: {},
    refreshToken: null,
    start_time: localStorage.getItem("start_time"),
    end_time: localStorage.getItem("end_time")
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
      localStorage.setItem("token", payload);
    },
    setrefreshToken(state, paylaod) {
      state.refreshToken = paylaod
      localStorage.setItem("refreshToken", paylaod)
    },
    setUser(state, paylaod) {
      state.user = paylaod;
      localStorage.setItem("user", JSON.stringify(paylaod))
    },
    setAddress(state, payload) {
      state.address = payload
      localStorage.setItem("address", payload)
    },
    setStartTime(state, payload) {
      state.start_time = payload
      localStorage.setItem("start_time", payload)
    },
    setEndTime(state, payload) {
      state.end_time = payload
      localStorage.setItem("end_time", payload)
    },
    setLng(state, paylaod) {
      state.lng = paylaod;
      localStorage.setItem("lng", paylaod)
    },
    setLat(state, paylaod) {
      state.lat = paylaod;
      localStorage.setItem('lat', paylaod);
    },
    exitToken(state, paylaod) {
      state.token = null;
      localStorage.removeItem("token")
    },
    exit(state, paylaod) {
      state.token = null
      state.user = {}
      state.refreshToken = null
      localStorage.clear()
    },
    removeAddress(state, paylaod) {
      state.lat = null;
      state.lng = null;
      state.address = null
      state.start_time = null;
      state.end_time = null
    }
  },
  actions: {}
});
