<template>
  <div class="wrapper">
    <div class="main-block">
      <div class="top">
        <ul class="left">
          <li>联系我们</li>
          <li>公司电话: 010-64489189</li>
          <li>公司邮箱: info@giantweather.com</li>
          <li>公司地址: 北京市朝阳区怡和阳光B座三层308室</li>
        </ul>
        <div class="right">
          <span>微信公众号</span>
          <img src="../assets/wechat-qrcode.png" alt="" srcset="" />
        </div>
      </div>
      <div class="bottom">
        <a>Copyright © 北京嘉韵楷达气象科技有限公司 All Right Reserved</a>
        <a href="https://beian.miit.gov.cn/"
          >京ICP备18018218号-1 京公网安备 11010102002019号</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.wrapper {
  background: #2462b6;
  position: sticky;
  top: 100vh;
  .main-block {
    max-width: 620px;
    margin: 0 auto;
    .top {
      display: flex;
      justify-content: space-between;
      padding: 40px 0 0 0;
      .left {
        margin: 0;
        li {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #bfbfbf;
          text-align: left;
          padding: 10px 0;
          &:nth-child(1) {
            font-size: 22px;
            color: #f7f8f9;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        span {
          font-size: 22px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #f7f8f9;
        }
        img {
          margin-top: 24px;
        }
      }
    }
    .bottom {
      display: flex;
      flex-direction: column;
      padding: 26px 0;
      align-items: center;
      a {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #bfbfbf;
        &:nth-child(2) {
          margin-top: 8px;
        }
      }
    }
  }
}
</style>