import axios from "axios";
import router from "@/router";
import store from "../store/store";
import { refresh } from "./user"
import { Toast } from "vant";

let refreshToken = localStorage.getItem("refreshToken")
const server = axios.create({
  baseURL: "http://110.87.103.59:18086/",
  timeout: 50000
});

server.interceptors.request.use(
  function (config) {
    let token = store.state.token
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: token
      }
    };
  },
  function (error) {
    return Promise.reject(error);
  }
);

server.interceptors.response.use(async function (response) {
    let isRefreshing = false
    let requests = []
    if (response.data.code === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const res = await refresh(refreshToken)

          store.commit("setToken", `${res.data.tokenHead}${res.data.token}`);
          response.config.headers.Authorization = `${res.data.tokenHead}${res.data.token}`
          isRefreshing = false

          requests.forEach((cb) => cb(response.config.headers.token))
          // 请求队列清空
          requests = []
          const resp = await server.request(response.config)
          return resp
        } catch (error) {
          this.$toast.error(error);
        }
      }
    } else if (response.data.code === 402) {
      router.push({
        path: '/Login',
        query: {
          redirect: location.hostname
        }
      });
    } else if (response.data.code === 500) {
      store.commit("exit")
    }
    return response.data;
  },
  function (error) {
    Toast.fail(error.response.data.message);
    return Promise.reject(error);
  })

export default server;
