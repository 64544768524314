var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{staticClass:"company-news"},[_c('img',{staticClass:"back",attrs:{"src":require("../assets/imgs/home/bkg1.png"),"alt":""}}),_c('div',{staticClass:"company-main"},[_c('h3',[_vm._v("公司新闻")]),_c('div',{staticClass:"card-group"},_vm._l((_vm.list),function(item){return _c('div',{key:item.contentId,staticClass:"card",on:{"click":function($event){return _vm.toNews(item.contentId)}}},[_c('img',{attrs:{"src":'http://api.giantweather.cn' + item.typeImg,"alt":""}}),_c('div',{staticClass:"card-desc"},[_c('span',[_vm._v(_vm._s(item.shortTitle || item.title))])])])}),0),_c('button',{on:{"click":() => {
            _vm.$router.push('/news');
          }}},[_vm._v(" 查看更多 ")])])]),_c('div',{staticClass:"production-server"},[_c('div',{staticClass:"production-server-main"},[_c('h3',[_vm._v("产品与服务 — 气象与科技")]),_c('div',{staticClass:"content"},[_vm._m(1),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"item",on:{"click":_vm.toDataDetail}},[_c('img',{attrs:{"src":require("../assets/imgs/home/server1.png"),"alt":""}}),_c('span',[_vm._v("气象数据加工")])]),_vm._m(2),_vm._m(3)])])])]),_vm._m(4)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("../assets/imgs/home/banner.jpg"),"alt":""}}),_c('ul',[_c('li',[_vm._v("气象预见未来 双碳绿色发展")]),_c('li',[_vm._v("深耕气象服务领域 积极应对气候变化")]),_c('li',[_vm._v("践行“双碳”之路 发展低碳农业 营造绿色生态")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("../assets/imgs/home/computer.png"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/imgs/home/server2.png"),"alt":""}}),_c('span',[_vm._v("智慧农业气象")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/imgs/home/server3.png"),"alt":""}}),_c('span',[_vm._v("智慧工程气象")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"production-server2"},[_c('img',{staticClass:"back",attrs:{"src":require("../assets/imgs/home/bkg2.jpg"),"alt":""}}),_c('div',{staticClass:"production-server2-main"},[_c('h3',[_vm._v("产品与服务 — 低碳与农业")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/imgs/home/server4.png"),"alt":"","srcset":""}}),_c('span',[_vm._v("碳足迹，记录农业生产低碳活动")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/imgs/home/server5.png"),"alt":"","srcset":""}}),_c('span',[_vm._v("碳计算，提供清晰碳排放量记录工具")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/imgs/home/server6.png"),"alt":"","srcset":""}}),_c('span',[_vm._v("碳管理，实现企业绿色价值")])])])])])
}]

export { render, staticRenderFns }