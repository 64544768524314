<template>
  <div class="wrapper">
    <img src="../assets/imgs/logo.png" @click="$router.push('/home')" />
    <Icon
      class="menu-btn"
      name="menu"
      @click.native="$emit('setMenuVisiableTrue')"
    />
  </div>
</template>

<script>
import Icon from "./Icon.vue";
export default {
  name: "MenuIcon",
  components: { Icon },
};
</script>

<style lang="less" scoped>
.wrapper {
  padding: 18px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #235ea9;
  img {
    width: 106px;
  }
  .menu-btn {
    font-size: 30px;
    color: #fff;
  }
}
</style>