import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "嘉韵楷达"
    },
    component: HomeView
  },
  {
    path: "/home",
    name: "home",
    meta: {
      title: "嘉韵楷达"
    },
    redirect: "/"
  },
  {
    path: "/product",
    name: "product",
    meta: {
      title: "嘉韵楷达-产品与服务"
    },
    component: () => import("../views/ProductionAndServer.vue")
  },
  {
    path: "/Login",
    name: "Login",
    component: () => import("../views/login/Login.vue")
  },
  {
    path: "/Register",
    name: "Register",
    component: () => import("../views/login/Register.vue")
  },
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    component: () => import("../views/login/ResetPassword.vue")
  },
  {
    path: "/news",
    name: "news",
    meta: {
      title: "嘉韵楷达-公司新闻"
    },
    component: () => import("../views/CompanyNews.vue")
  },
  {
    path: "/about",
    name: "about",
    meta: {
      title: "嘉韵楷达-关于我们"
    },
    component: () => import("../views/AboutUs.vue")
  },
  {
    path: "/meteorological/MPdata",
    name: "MPdata",
    component: () => import("../views/meteorological/MPdata.vue")
  },
  // 地图选择页面
  {
    path: "/meteorological/openMap",
    name: "OpenMap",
    meta: {
      isShow: false
    },
    component: () => import("../views/meteorological/openMap.vue")
  },
  // 高精度网格实况
  {
    path: "/meteorological/latticeOnline",
    name: "LatticeOnline",
    component: () => import("../views/meteorological/latticeOnline.vue")
  },
  // 高精度网格预报
  {
    path: "/meteorological/gridPrediction",
    name: "LatticeOnline",
    component: () => import("../views/meteorological/gridPrediction.vue")
  },
  // 海浪预报
  {
    path: "/meteorological/seaWave",
    name: "LatticeOnline",
    component: () => import("../views/meteorological/seaWave.vue")
  },
  // 天气实况
  {
    path: "/meteorological/siteOnline",
    name: "SiteOnline",
    component: () => import("../views/meteorological/SiteOnline.vue")
  },
  // 天气预报
  {
    path: "/meteorological/prediction",
    name: "SiteOnline",
    component: () => import("../views/meteorological/prediction.vue")
  },
  // 天气预警
  {
    path: "/meteorological/earlyWarning",
    name: "SiteOnline",
    component: () => import("../views/meteorological/earlyWarning.vue")
  },
  {
    path: "/contact",
    name: "contact",
    meta: {
      title: "嘉韵楷达-联系我们"
    },
    component: () => import("../views/ContactUs.vue")
  },
  {
    path: "/newsdetail/:id",
    name: "newsdetail",
    meta: {
      title: "嘉韵楷达-新闻详情"
    },
    component: () => import("../views/NewsDetail.vue"),
    props: true
  },
  {
    path: "/email",
    name: "Email",
    component: () => import("../views/Email.vue")
  },
  {
    path: "/message",
    name: "Message",
    component: () => import("../views/Message.vue")
  },
  {
    path: "/mycenter",
    name: "Mycenter",
    component: () => import("../views/mycenter.vue")
  },
  {
    path: "/payment/payment",
    name: "Payment",
    component: () => import("../views/payment/payment.vue")
  },
  {
    path: "/payment/billingRules",
    name: "BillingRules",
    component: () => import("../views/payment/billingRules.vue")
  },
  {
    path: "/payment/transaction",
    name: "Transaction",
    component: () => import("../views/payment/Transaction.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router;
